/* eslint-disable max-len */
import React from 'react';

function SvgPinIcon(props) {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.8361 24.5634C16.2242 25.4019 15.6091 26.2448 14.9898 27.0934C14.5981 26.562 14.2127 26.0399 13.8318 25.5239C13.0572 24.4744 12.301 23.45 11.5476 22.4235C11.1716 21.9107 10.7939 21.3991 10.4163 20.8874C9.4156 19.5317 8.41499 18.176 7.44495 16.8006C5.43975 13.9602 5.1342 10.9265 6.65789 7.82928C8.20982 4.67625 10.9012 2.95396 14.5411 2.66675C18.8111 2.32903 23.1482 5.63601 24.0054 9.84407C24.4879 12.2129 24.0412 14.4269 22.7136 16.4797C22.5755 16.6938 22.4267 16.9011 22.276 17.107C20.4727 19.5807 18.6685 22.0527 16.8361 24.5634ZM23.9081 17.7613C25.2311 15.9293 25.938 13.8967 26 11.6376C25.9549 11.3545 25.9172 11.0697 25.8795 10.785C25.7973 10.1651 25.7152 9.54524 25.5573 8.94299C24.1713 3.65971 18.4184 0.119403 13.0153 1.19174C5.03841 2.775 1.41321 11.3831 6.04428 17.7916C7.91568 20.3817 9.81308 22.955 11.7102 25.5279C12.3192 26.3538 12.9281 27.1796 13.5362 28.006C13.7137 28.2475 13.9164 28.4808 14.1473 28.6747C14.6646 29.1096 15.3231 29.1096 15.8389 28.6675C16.0849 28.4563 16.2947 28.198 16.4847 27.9377C16.9229 27.3391 17.3612 26.7406 17.7994 26.1421C19.8423 23.3526 21.8851 20.5633 23.9081 17.7613ZM15.5485 9.57642C17.1143 9.60033 18.419 10.9242 18.418 12.4872C18.4167 14.103 17.123 15.4117 15.5143 15.4237C13.913 15.4365 12.587 14.1237 12.5815 12.5198C12.5755 10.8833 13.9181 9.55159 15.5485 9.57642ZM15.5097 17C18.0175 16.9926 20.0064 14.996 20 12.4918C19.9935 9.99579 17.9759 7.9877 15.4861 8.00011C12.982 8.01161 10.9945 10.0123 11 12.5148C11.0056 15.0199 13.0038 17.0069 15.5097 17Z'
      />
    </svg>
  );
}

export default SvgPinIcon;
