import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import Imgix from 'shared/components/imgix';
import { Button } from 'components/core';

export default function BestProductsSection(props) {
  const { onCallToActionClick } = props;
  const { t } = useTranslation();

  return (
    <BestProductsContainer data-ga-label='the-best-products'>
      <HeaderContainer>
        <Header>
          {t(
            'home.header.theBestProducts',
            'The best products. The leading cannabis brands. Order now for pickup or delivery.'
          )}
        </Header>
      </HeaderContainer>
      <StyledButton onClick={onCallToActionClick} size='large'>
        {t('home.shopNow', 'Shop now')}
      </StyledButton>
      <ImageContainer>
        <BestProductsImage
          htmlAttributes={{ alt: t('home.bestProducts', 'best products') }}
          src='https://images.dutchie.com/marketing-v3/best-products.png'
        />
      </ImageContainer>
    </BestProductsContainer>
  );
}

const BestProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 128px;
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    padding-top: 98px;
  }
`;

const HeaderContainer = styled.div`
  margin: 0 auto 22px;
  max-width: 580px;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    max-width: 485px;
  }
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    margin: 0 20px 24px;
  }
`;

const Header = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  ${({ theme }) => theme.breakpoints.up(`sm`)} {
    display: inline;
    :first-of-type::after {
      content: ' ';
    }
  }
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    font-size: 31px;
    line-height: 39px;
    color: ${({ theme }) => theme.colors.grey[25]};
  }
`;

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors.flora};
  font-size: 18px;
  line-height: 12px;
  width: 153px;
  height: 49px;
  margin-bottom: 15px;
  text-transform: none;
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    font-size: 14px;
    width: 116px;
    height: 39px;
    padding: 12px 20px;
    margin-bottom: 45px;
  }
`;

const ImageContainer = styled.div`
  width: 100vw;
  max-width: 100%;
  display: flex;
  margin-bottom: 30px;
`;

const BestProductsImage = styled(Imgix)`
  max-width: 1120px;
  width: 90vw;
  margin: 0 auto;
`;
