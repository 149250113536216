import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useComboboxContext } from '@reach/combobox';

import useTranslation from 'src/hooks/use-translation';
import { Tab, Tabs } from 'components/core';
import { ARIA_ROLES, ORDER_TYPE_DELIVERY, ORDER_TYPE_PICKUP } from 'shared/constants';
import { NoSsr } from '@material-ui/core';
import { OrderTypeTabsProps } from './types';

const ANCHOR_COMPONENT = `a`;

export function OrderTypeTabs({ value, onChange }: OrderTypeTabsProps): JSX.Element {
  const { t } = useTranslation();
  const tabIndicatorProps = useTabIndicatorProps();

  return (
    <TabContainer>
      <NoSsr>
        <Tabs
          aria-label={t('homepage.delivery-tab-options', 'Select dispensaries that support pickup or delivery')}
          onChange={onChange}
          TabIndicatorProps={tabIndicatorProps}
          value={value}
        >
          <Tab
            aria-label={t('homepage.pickup', 'Pickup')}
            component={ANCHOR_COMPONENT}
            label={t('common.pickup', 'Pickup')}
            role={ARIA_ROLES.TAB}
            value={ORDER_TYPE_PICKUP}
          />
          <Tab
            aria-label={t('homepage.delivery', 'Delivery')}
            component={ANCHOR_COMPONENT}
            label={t('common.delivery', 'Delivery')}
            role={ARIA_ROLES.TAB}
            value={ORDER_TYPE_DELIVERY}
          />
        </Tabs>
      </NoSsr>
    </TabContainer>
  );
}

function useTabIndicatorProps(): Partial<React.HTMLAttributes<HTMLDivElement>> {
  const { isExpanded } = useComboboxContext();

  /**
   * override the transition style for the initial render so that the
   * selected tab indicator doesn't slide to the active element when the
   * popover opens. After initial render, remove the override to allow for
   * the default sliding animation when the user changes selection.
   */
  const [shouldSlide, setShouldSlide] = useState(false);
  useEffect(() => {
    let timeoutId: number;
    if (isExpanded) {
      /**
       * The popover has opened, so the indicator should now slide.
       * This needs to be in a setTimeout so that the change happens
       * after the initial render.
       */
      timeoutId = setTimeout(() => setShouldSlide(true));
    } else {
      setShouldSlide(false);
    }
    return () => clearTimeout(timeoutId);
  }, [isExpanded]);

  return { style: !shouldSlide ? { transition: 'none' } : undefined };
}

const TabContainer = styled.div`
  margin: 0 -22px 19px;
  &&&& * {
    font-size: 16px;
  }
  .MuiTabs-flexContainer {
    margin-bottom: 12px;
  }
  .Mui-focusVisible {
    box-shadow: 0 0 0 2pt rgb(11 153 230 / 40%);
  }
  .MuiTab-textColorInherit {
    color: ${({ theme }) => theme.colors.grey[25]};
  }
  .MuiTab-textColorInherit.Mui-selected {
    color: ${({ theme }) => theme.colors.opal};
  }
  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.opal};
  }
  > div {
    padding-left: 24px;
  }
`;
