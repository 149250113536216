import React from 'react';

import { LocationObject } from 'shared/helpers/mapbox';
import useCart from 'hooks/use-cart';
import ROUTES from 'src/routes';
import routeTo from 'utils/route-to';

import { AddressAutocomplete } from 'components/address-autocomplete';
import { DesktopAddressCombobox } from './desktop-address-combobox';

export const DesktopAddressComboboxController = (): JSX.Element => {
  const Cart = useCart();
  function onSelect(location: LocationObject): void {
    Cart.updateAddress({ location });
    routeTo({ url: ROUTES.DISPENSARIES });
  }

  return (
    /**
     * AddressAutocomplete is wrapping the presentational component here
     * rather than in the presentational component's file so we can access
     * AddressAutocomplete context from within the presentational component.
     */
    <AddressAutocomplete onSelect={onSelect}>
      <DesktopAddressCombobox />
    </AddressAutocomplete>
  );
};
