import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

import useTranslation from 'hooks/use-translation';

import { DesktopOnly } from 'shared/components';
import { Button } from 'components/core';
import { MyAccountLink } from './my-account-link';

type AccountDropdownProps = {
  isLoggedIn: boolean;
  onLoginClick?: () => void;
  onSignUpClick?: () => void;
  isOnLight?: boolean;
};

export function AccountDropdown({
  isLoggedIn = false,
  onLoginClick,
  onSignUpClick,
  isOnLight = false,
}: AccountDropdownProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      {isLoggedIn && (
        <Container isOnLight={isOnLight}>
          <MyAccountLink displayAccountIcon={false} isOnLight={isOnLight} />
        </Container>
      )}
      {!isLoggedIn && (
        <ButtonWrapper>
          <StyledLoginButton data-testid='login-button' noDim size='small' onClick={onLoginClick} isOnLight={isOnLight}>
            {t('home.login', 'Log in')}
          </StyledLoginButton>
          <StyledDesktopOnly mediaQuery='sm'>
            <StyledSignupButton
              data-testid='signup-button'
              noDim
              size='small'
              onClick={onSignUpClick}
              isOnLight={isOnLight}
            >
              {t('home.signUp', 'Sign up')}
            </StyledSignupButton>
          </StyledDesktopOnly>
        </ButtonWrapper>
      )}
    </>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const StyledLoginButton = styled(Button)`
  color: ${({ theme, isOnLight }) => (isOnLight ? theme.colors.black : theme.colors.white)};
  font-size: 16px;
  padding: 11px 18px;
  background: transparent;
  text-transform: none;
  margin-right: 3px;
`;

const StyledSignupButton = styled(Button)`
  --button-color: ${({ theme, isOnLight }) => (isOnLight ? theme.colors.black : theme.colors.white)};

  color: var(--button-color);
  background: transparent;
  border: 1px solid var(--button-color);
  height: 50px;
  text-transform: none;
  font-size: 16px;

  button {
    font-size: 16px;
    padding: 16px 20px;
  }
`;

const Container = styled.div`
  --button-color: ${({ theme, isOnLight }) => (isOnLight ? theme.colors.black : theme.colors.white)};

  display: flex;
  background: transparent;
  place-content: center;
  place-items: center;
  height: 50px;
  border-radius: 100px;
  cursor: pointer;
  color: var(--button-color);

  button {
    margin: 0;
    height: 50px;
    width: 100%;
    display: flex;
    place-content: center;
    place-items: center;
    z-index: 10;
    font-size: 16px !important;
  }
`;

const StyledDesktopOnly = styled(DesktopOnly)`
  position: relative;
  ${space}
`;
