import { useState, Dispatch, SetStateAction } from 'react';

import useSearchPreferences from 'hooks/use-search-preferences';
import { useAddressAutocomplete } from 'src/components/address-autocomplete';
import useTranslation from 'src/hooks/use-translation';
import { DesktopAddressComboboxProps, OrderType } from './types';

export function useDesktopAddressCombobox(): DesktopAddressComboboxProps {
  const { orderType, setOrderType } = useSearchPreferences();
  const { inputRef } = useAddressAutocomplete();
  const { errorMessage, setShowError } = useError();

  function onChangeOrderType(_e: React.ChangeEvent, val: OrderType): void {
    setOrderType(val);
  }

  function onInputChange(): void {
    setShowError(false);
  }

  function onStartShoppingClick(): void {
    inputRef.current?.focus();
    setShowError(true);
  }

  return {
    errorMessage,
    onInputChange,
    onChangeOrderType,
    onStartShoppingClick,
    orderType,
  };
}

type UseErrorReturn = {
  errorMessage: string | null;
  setShowError: Dispatch<SetStateAction<boolean>>;
};
function useError(): UseErrorReturn {
  const { t } = useTranslation();
  const { suggestions } = useAddressAutocomplete();
  const [showError, setShowError] = useState<boolean>(false);

  const errorMessage = suggestions.length
    ? t('common.selectAddressFromBelow', 'Please select an address below.')
    : t('common.enterAddress', 'Please enter a valid address.');

  return { errorMessage: showError ? errorMessage : null, setShowError };
}
