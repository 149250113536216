import React, { useCallback, useEffect, useState } from 'react';

import ROUTES from 'src/routes';
import { ORDER_TYPE_PICKUP } from 'shared/constants';

import routeTo from 'utils/route-to';
import useCart from 'hooks/use-cart';
import useSearchPreferences from 'hooks/use-search-preferences';
import type { OrderTypes } from 'src/state/search-preferences';
import AddressDialog from './address-dialog';

type AddressDialogControllerProps = {
  onClose: () => void;
  open: boolean;
};

export function AddressDialogController({ onClose, open }: AddressDialogControllerProps): JSX.Element | null {
  const Cart = useCart();
  const SearchPreferences = useSearchPreferences();
  const [location, setLocation] = useState(null);
  const [orderType, setOrderType] = useState(ORDER_TYPE_PICKUP);
  const [loadingDispensariesList, setLoadingDispensariesList] = useState(false);

  function onClear(): void {
    setLocation(null);
  }

  const handleClose = useCallback(() => {
    onClose();
    onClear();
  }, [onClose]);

  const cleanupOnUnmount = useCallback(() => {
    if (open) {
      handleClose();
    }
  }, [handleClose, open]);

  // If this component is unmounted while open, we need to reset the `showAddressDialog` state
  // for the parent component by calling `onClose` and reset the local `location` state.
  useEffect(() => cleanupOnUnmount, [cleanupOnUnmount]);

  function onClickContinue(): void {
    Cart.updateAddress({ location });
    SearchPreferences.setOrderType(orderType);
    setLoadingDispensariesList(true);
    routeTo({ url: ROUTES.DISPENSARIES });
  }

  function onChangeOrderType(_e: React.ChangeEvent, newOrderType: OrderTypes): void {
    setOrderType(newOrderType);
  }

  return (
    <AddressDialog
      open={open}
      onClose={handleClose}
      onClear={onClear}
      onClickContinue={onClickContinue}
      onSelectLocation={setLocation}
      selectedLocation={location}
      showContinueButton={!!location}
      onChangeOrderType={onChangeOrderType}
      selectedOrderType={orderType}
      loadingDispensariesList={loadingDispensariesList}
    />
  );
}
