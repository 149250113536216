import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { Button } from 'components/core';

export default function ForEveryoneSection(props) {
  const { onCallToActionClick } = props;
  const { t, Trans } = useTranslation();
  return (
    <Section container>
      <ImageContainer>
        <Campfire />
      </ImageContainer>
      <LeftColumn>
        <Content data-ga-label='something-for-everyone'>
          <SectionHeading>{t('home.somethingForEveryone', 'Something for everyone.')}</SectionHeading>
          <P>
            <Trans i18nKey='home.hasEverythingYouNeed'>
              {/* eslint-disable-next-line max-len */}
              With Dutchie, it’s easy to get your favorite cannabis products quickly. Whether you’re in the mood for a
              lazy night in, an evening out with friends, or relief from an ailment, there’s something for everyone.
            </Trans>
          </P>
          <ButtonContainer>
            <StyledButton onClick={onCallToActionClick} size='medium'>
              {t('home.shopNow', 'Shop now')}
            </StyledButton>
          </ButtonContainer>
        </Content>
      </LeftColumn>
    </Section>
  );
}

const ButtonContainer = styled.div``;

const StyledButton = styled(Button)`
  background: transparent;
  border: 1px solid #171a1c;
  color: #171a1c;
  text-transform: none;
`;

const Campfire = styled.div`
  background: url('https://images.dutchie.com/rebrand/people-collage.png') no-repeat right center;
  background-size: contain;
  position: absolute;
  right: 0;
  height: 100%;
  width: 90%;
  min-height: 269px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    min-height: 350px;
    background: url('https://images.dutchie.com/rebrand/people-collage.png') no-repeat;
    background-size: cover;
    background-position: center 20%;
    width: 100%;
  }
`;

const Content = styled.div`
  max-width: 533px;
  margin: 0 auto;
`;

const SectionHeading = styled.p`
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 19px;
    max-width: 200px;
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  min-height: 269px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    position: relative;
  }
`;

const LeftColumn = styled.div`
  color: #171a1c;
  height: auto;
  left: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55%;
  padding: 100px 0;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    width: 100%;
    min-height: 361px;
    margin-top: 50px;
  }
`;

const P = styled.p`
  font-size: 18px;
  line-height: 32px;
  margin: 18px 0 34px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    font-size: 13px;
    line-height: 23px;
    max-width: 273px;
  }
`;

const Section = styled.section`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 360px;
  position: relative;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    flex-direction: column;
  }
`;
