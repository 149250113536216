import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import useColors from 'hooks/use-colors';
import useTranslation from 'hooks/use-translation';

import PinIcon from 'assets/pin-icon';
import ExitIcon from 'assets/exit-icon-2';
import { ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY } from 'shared/constants';
import { Clickable } from 'shared/components';
import { Button, PlacesAutocompleteInput, PlacesList, Tab, Tabs } from 'components/core';

const Transition = React.forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />);

export default function AddressDialog({
  open,
  onClose,
  onClear,
  onClickContinue,
  onSelectLocation,
  selectedLocation,
  showContinueButton,
  onChangeOrderType,
  selectedOrderType = ORDER_TYPE_PICKUP,
  loadingDispensariesList,
}) {
  const { t } = useTranslation();
  const colors = useColors();

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition} onClose={onClose}>
      <Content>
        <HeaderContainer>
          <HeaderText>{t('mobileLocationSettings.getStarted', 'Get Started')}</HeaderText>
          <Clickable onClick={onClose}>
            <ExitIcon height='20px' />
          </Clickable>
        </HeaderContainer>

        <StyledPlacesAutocompleteInput
          autoFocus
          data-cy='address-autocomplete-input'
          data-test='address-autocomplete-input'
          onChange={onSelectLocation}
          onClear={onClear}
          placeholder={t('globalNav.enterNewAddress', 'Enter an address or city...')}
          startAdornment={<PinIcon fill={colors.grey[70]} height='21' />}
          variant='secondary'
          useDropdown={false}
          hideList
          isLocationSettings
        >
          {/* TODO: refactor once the PlacesAutocompleteInput component is decoupled CH-73252 */}
          {({ suggestionConfig, currentLocationLoading, handleCurrentLocationSelected, errorConfig }) => {
            const selectedIndex = _.findIndex(suggestionConfig.suggestionsForSearch, {
              id: selectedLocation?.id,
            });
            return (
              <>
                <DropShadow />

                <StyledTabs centered value={selectedOrderType} onChange={onChangeOrderType}>
                  <Tab value={ORDER_TYPE_PICKUP} aria-label={ORDER_TYPE_PICKUP} label={t('common.pickup', 'Pickup')} />
                  <Tab
                    value={ORDER_TYPE_DELIVERY}
                    aria-label={ORDER_TYPE_DELIVERY}
                    label={t('common.delivery', 'Delivery')}
                  />
                </StyledTabs>
                <PlacesListContainer>
                  <PlacesList
                    selectedIndex={selectedIndex}
                    showCheckmarkForSelectedAddress
                    suggestionConfig={suggestionConfig}
                    currentLocationLoading={currentLocationLoading}
                    handleCurrentLocationSelected={handleCurrentLocationSelected}
                    errorConfig={errorConfig}
                    currentLocationStyles={{ mx: 24, height: 50 }}
                    listContainerStyles={{ mx: 24 }}
                    isInDropdown={false}
                    setIsPlacesListFocused={_.constant(null)}
                  />
                </PlacesListContainer>
              </>
            );
          }}
        </StyledPlacesAutocompleteInput>
      </Content>

      {showContinueButton && (
        <ContinueButton loading={loadingDispensariesList} onClick={onClickContinue}>
          {t('globalNav.continue', 'Continue')}
        </ContinueButton>
      )}
    </Dialog>
  );
}

const StyledPlacesAutocompleteInput = styled(PlacesAutocompleteInput)`
  height: 53px;
  input {
    ::placeholder {
      font-size: 16px;
    }
  }
`;

const DropShadow = styled.div`
  height: 23px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.blueGrey[95]}`};
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-content: space-between;
  padding: 23px 24px 21px;
  align-items: center;
`;

const HeaderText = styled.h1`
  font-weight: bold;
  font-size: 20px;
  margin-right: auto;
  color: ${({ theme }) => theme.colors.grey[30]};
`;

const Content = styled.div`
  height: 100%;
  .MuiFormControl-root {
    padding: 0 24px;
  }
`;

const StyledTabs = styled(Tabs)`
  height: 50px;
  padding-top: 13px;
  margin-bottom: 23px;

  &.MuiTabs-root {
    box-shadow: none;
    color: ${({ theme }) => theme.colors.grey[30]};
  }

  &&& {
    .MuiTabs-indicator {
      height: 3px;
      background-color: ${({ theme }) => theme.colors.opal};
    }

    .Mui-selected {
      color: ${({ theme }) => theme.colors.opal};
    }
  }
`;

const ContinueButton = styled(Button)`
  line-height: 0;
  padding: 25px 15px;
  margin: 13px 24px 34px;
`;

const PlacesListContainer = styled.div`
  padding: 0 24px;
  width: 100vw;
  button {
    width: 100%;
    margin: 0;
  }
`;
