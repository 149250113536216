import React from 'react';
import styled, { css } from 'styled-components';
import { margin } from 'styled-system';

export default function IncentiveCard({ title, description, icon, ...props }) {
  return (
    <Card className='incentive-card'>
      <IconContainer {...props}>{icon}</IconContainer>
      <Container>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Container>
    </Card>
  );
}

const Card = styled.div`
  width: 318px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    flex-direction: row;
  }
`;

const Container = styled.div`
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    margin-left: 25px;
  }
`;

const IconContainer = styled.div`
  max-height: 100px;
  height: 100%;
  display: flex;
  ${margin};
  svg {
    height: 100%;
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    width: 150px;
  }
`;

const Title = styled.h2`
  ${({ theme: { colors, breakpoints } }) => css`
    margin: 36px 0 0 0;
    font-size: 22px !important;
    color: ${colors.black};
    font-weight: bold;

    ${breakpoints.down(`sm`)} {
      font-size: 19px !important;
      line-height: 21px;
      margin-top: 25px;
    }
  `}
`;

const Description = styled.p`
  ${({ theme: { colors, breakpoints } }) => css`
    font-size: 15px;
    margin-top: 18px;
    line-height: 24px;
    color: ${colors.black};

    ${breakpoints.down(`md`)} {
      max-width: 130px;
    }

    ${breakpoints.down(`sm`)} {
      font-size: 14px;
      margin-top: 8px;
      max-width: 287px;
    }
  `}
`;
