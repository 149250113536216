import React, { useCallback } from 'react';
import { useObserver } from 'mobx-react-lite';

import useUser from 'hooks/use-user';
import { useModals, ModalNames } from 'components/modals';

import { AccountDropdown } from './account-dropdown';

function AccountDropdownController({ isOnLight }): JSX.Element {
  const { openModal } = useModals();
  const User = useUser();
  const isLoggedIn = useObserver(() => User.isLoggedIn);

  const handleLoginClick = useCallback(() => {
    void openModal(ModalNames.login);
  }, [openModal]);

  const handleSignUpClick = useCallback(() => {
    void openModal(ModalNames.signUp);
  }, [openModal]);

  return (
    <AccountDropdown
      isLoggedIn={isLoggedIn}
      isOnLight={isOnLight}
      onLoginClick={handleLoginClick}
      onSignUpClick={handleSignUpClick}
    />
  );
}

export { AccountDropdownController as AccountDropdown };
