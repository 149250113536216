import React, { useCallback } from 'react';
import { useObserver } from 'mobx-react-lite';

import useUser from 'hooks/use-user';

import { useModals, ModalNames } from 'components/modals';

import MenuBottomDrawer from './menu-bottom-drawer';

type MenuBottomDrawerWrapperProps = {
  onCtaClick?: () => void;
  onClose: () => void;
  open: boolean;
  toggleAccountDrawer: () => void;
};

const MenuBottomDrawerWrapper = ({ onClose, ...props }: MenuBottomDrawerWrapperProps): JSX.Element => {
  const { openModal } = useModals();
  const User = useUser();
  const isLoggedIn = useObserver(() => User.isLoggedIn);

  const handleLoginClick = (): void => {
    void openModal(ModalNames.login);
    void onClose();
  };

  const handleSignUpClick = useCallback(() => {
    void openModal(ModalNames.signUp);
    void onClose();
  }, [openModal, onClose]);

  return (
    <MenuBottomDrawer
      onLoginClick={handleLoginClick}
      onSignUpClick={handleSignUpClick}
      isLoggedIn={isLoggedIn}
      onClose={onClose}
      {...props}
    />
  );
};

export default MenuBottomDrawerWrapper;
