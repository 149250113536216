import React from 'react';
import { space } from 'styled-system';
import styled, { css } from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { BottomDrawer, Button, Link } from 'components/core';

type MenuBottomDrawerProps = {
  onClose: () => void;
  onCtaClick?: () => void;
  open: boolean;
  onLoginClick: () => void;
  onSignUpClick: () => void;
  toggleAccountDrawer: () => void;
  isLoggedIn: boolean;
};

const MenuBottomDrawer = ({
  onClose,
  onCtaClick,
  open,
  onLoginClick,
  onSignUpClick,
  isLoggedIn,
  toggleAccountDrawer,
}: MenuBottomDrawerProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <BottomDrawer open={open} onClose={onClose} heading={t('common.menu', 'Menu')}>
      <MenuItemContainer>
        <Link href='/'>
          {/* eslint-disable-next-line styled-components-a11y/anchor-is-valid */}
          <MenuItem mt={35} mb={35} onClick={onCtaClick} onKeyDown={onClose} role='button' tabIndex={0}>
            {t('common.shopNow', 'Shop now')}
          </MenuItem>
        </Link>
        <Link href='/dispensary'>
          {/* eslint-disable-next-line styled-components-a11y/anchor-is-valid */}
          <MenuItem mb={35}>{t('common.forBusinesses', 'For Businesses')}</MenuItem>
        </Link>
      </MenuItemContainer>

      <ButtonContainer>
        {isLoggedIn ? (
          <MyAccountButton onClick={toggleAccountDrawer}>{t('common.myAccount', 'My Account')}</MyAccountButton>
        ) : (
          <>
            <LoginButton onClick={onLoginClick}>{t('common.login', 'Log in')}</LoginButton>
            <SignupButton onClick={onSignUpClick}>{t('common.signup', 'Sign up')}</SignupButton>
          </>
        )}
      </ButtonContainer>
    </BottomDrawer>
  );
};

export default MenuBottomDrawer;

const MenuItem = styled.a`
  ${space}
  font-size: 26px;
  cursor: pointer;
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0;
`;

const ButtonStyles = css`
  height: 50px;
  width: 50%;
`;

const LoginButton = styled(Button)`
  ${ButtonStyles}
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  border: ${({ theme }) => css`1px solid ${theme.colors.black}`};
  margin-right: 8px;
  font-size: 16px;
  text-transform: none;
`;

const SignupButton = styled(Button)`
  ${ButtonStyles}
  background: ${({ theme }) => theme.colors.flora};
  font-size: 16px;
  text-transform: none;
`;

const MyAccountButton = styled(LoginButton)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
`;
