import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import { Clickable } from 'shared/components';
import { ARIA_ROLES } from 'shared/constants';

export function TopBanner(): JSX.Element {
  const { Trans } = useTranslation();

  return (
    <Banner onClick={() => window.open('https://dutchie.com/dispensary')}>
      <Content role={ARIA_ROLES.PRESENTATION}>
        <p>
          <Trans i18nKey='home.acquisition-and-funds-banner'>
            <span>
              <strong>Are you a dispensary?</strong>
            </span>
            <span>
              Dutchie provides everything you need to easily run your cannabis business, all in one place. &nbsp;
              <BlueText>Learn More</BlueText>
            </span>
          </Trans>
        </p>
      </Content>
    </Banner>
  );
}

const Banner = styled(Clickable)`
  && {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.07);
    padding: 13px 0;
    display: flex;
    width: 100%;
    height: 42px;
    font-size: 13px;
    background-color: #061625;
    ${({ theme }) => theme.breakpoints.down(`sm`)} {
      height: unset;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

const Content = styled.div`
  display: flex;
  place-content: center;
  width: 100%;
  height: 100%;
  span,
  p {
    display: flex;
    place-content: center;
    place-items: center;
    white-space: pre-wrap;
    color: ${({ theme }) => theme.colors.white};
    svg {
      height: 22px;
      margin-top: -2px;
    }
    svg:first-of-type {
      margin-right: -4px;
    }
    ${({ theme }) => theme.breakpoints.down(`sm`)} {
      max-width: 349px;
      margin: 0 auto;
      line-height: 21px;
      text-align: center;
      font-size: 13px;
      svg {
        height: 17px;
        vertical-align: sub;
        margin-top: 0;
      }
    }
  }
  strong {
    margin-right: 10px;
  }
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    span,
    p {
      display: initial;
    }
  }
`;

const BlueText = styled.span`
  &&& {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
  }
`;
