import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import useTranslation from 'hooks/use-translation';

import ROUTES from 'src/routes';
import { topCitiesList } from 'shared/constants';
import { FooterDivider, Link } from 'components/core';

export default function FooterAddition() {
  const { t } = useTranslation();

  return (
    <Footer>
      <Container container justify='space-between'>
        <Heading>{t('footer.topCities', 'Top Cities')}</Heading>
        <Grid container item lg={6} sm={12} spacing={3} direction='row'>
          {_.map(topCitiesList, (cities, index) => (
            <NoHeaderFooterList key={`${index}-${cities.key}`} item md={3} xs={3}>
              {_.map(cities, ({ key, label }) => (
                <Link key={key} href={`/${key}`}>
                  {label}
                </Link>
              ))}
            </NoHeaderFooterList>
          ))}
        </Grid>
        <FooterDivider color='#446583' />
      </Container>
      <StyledDutchiePlusLink href={ROUTES.PLUS}>{t('common.dutchiePlus', 'Dutchie plus')}</StyledDutchiePlusLink>
    </Footer>
  );
}

const Heading = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  max-width: 261px;
  margin: 0 0 13px;
  color: #70a0ca;
  position: absolute;
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    font-size: 13px;
    line-height: 16px;
  }
`;

const Container = styled(Grid)`
  padding: 50px 84px 0px 82px;
  max-width: 1534px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: -1px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding: 40px 31px 0px;
  }
`;

const Footer = styled.div`
  background-color: ${({ color, theme }) => color || theme.colors.opal12};
  color: #e3e6e7;
  font-size: 13px;
  line-height: 22px;
  width: 100%;
  align-self: flex-end;
  a {
    color: #e3e6e7;
  }
`;

const FooterList = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-top: 38px !important;
  justify-content: center;
  a {
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    justify-content: flex-start;
  }
`;

const NoHeaderFooterList = styled(FooterList)`
  margin-top: 35px !important;
`;

// TODO: remove when test is removed
const StyledDutchiePlusLink = styled.a`
  position: absolute;
  cursor: default;
  pointer-events: none;
  color: transparent !important;
  bottom: 0;
`;
