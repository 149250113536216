import React from 'react';
import styled, { css } from 'styled-components';

import useColors from 'hooks/use-colors';
import useTranslation from 'hooks/use-translation';

import { DutchieLogo } from 'assets/marketing/rebrand/dutchie-logo';
import { MenuHamburger } from 'assets/marketing/rebrand/menu-hamburger';
import PinIcon from 'assets/marketing/pin-icon';
import { Button } from 'components/core';
import { VisuallyHidden } from 'components/core/visually-hidden';
import { MAIN_CONTENT_ID } from 'components/skip-to-main/constants';
import { ARIA_ROLES } from 'shared/constants';
import { Clickable, DesktopOnly, MobileOnly } from 'shared/components';

import { AccountDropdown } from './account-dropdown';
import { HeroLinks } from './hero-links';
import { DesktopAddressCombobox } from './desktop-address-combobox.tsx';

export default function HomeHero({ openAddressDialog, toggleFooterModal }) {
  const colors = useColors();
  const { t, Trans } = useTranslation();

  return (
    <Container data-ga-label='hero' role={ARIA_ROLES.BANNER}>
      <Hero>
        <Column>
          <NavWrapper>
            <LinkContainer>
              <DutchieLogo height='33px' fill={colors.white} />
              <DesktopOnly display='block' mediaQuery='sm'>
                <HeroLinks />
              </DesktopOnly>
            </LinkContainer>
            <DesktopOnly display='block' mediaQuery='sm'>
              <AccountDropdown />
            </DesktopOnly>
            <MobileOnly display='block' mediaQuery='xs'>
              <HamburgerBox onClick={toggleFooterModal}>
                <MenuHamburger />
                <VisuallyHidden>{t('common.homepage.menu-icon-action', 'Open/Close/Toggle Menu')}</VisuallyHidden>
              </HamburgerBox>
            </MobileOnly>
          </NavWrapper>
          <div role={ARIA_ROLES.REGION} id={MAIN_CONTENT_ID}>
            <TextWrapper>
              <h1>
                <Trans i18nKey='home.orderCannabisFrom'>
                  Order cannabis online from the best dispensaries near you.
                </Trans>
              </h1>
            </TextWrapper>

            {/* Desktop Address Input */}
            <DesktopOnly display='block' mediaQuery='sm'>
              <DesktopAddressCombobox />
            </DesktopOnly>

            {/* Mobile Address Input */}
            <MobileOnly display='block' mediaQuery='xs'>
              <MobileAddressButton
                onClick={openAddressDialog}
                data-cy='home-hero-address-input-mobile'
                data-test='home-hero-address-input-mobile'
                data-testid='homeAddressInput'
              >
                <StyledPinIcon fill={colors.searchIconFill} height='23px' />
                {t('home.hero.mobileAddressPlaceholder', 'Enter an address or city')}
              </MobileAddressButton>
            </MobileOnly>
          </div>
        </Column>
      </Hero>
    </Container>
  );
}

const HamburgerBox = styled(Clickable)`
  cursor: pointer;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const DefaultHeroStyles = css`
  /* TODO: import in color */
  background: linear-gradient(
      90deg,
      #0b2033 47.58%,
      rgba(11, 32, 51, 0.85) 57.74%,
      rgba(11, 32, 51, 0.64) 81.15%,
      rgba(11, 32, 51, 0.6) 100%
    ),
    url('https://images.dutchie.com/marketing-v3/home-page-v3-herov3.png?auto=format') no-repeat no-repeat center center;
  min-height: 680px;
`;

const MobileHeroStyles = css`
  /* TODO: import in color */
  background: linear-gradient(0deg, rgb(16, 47, 77, 0.84), rgb(16, 47, 77, 0.84)),
    url('https://images.dutchie.com/marketing-v3/mobile-home-page-herov2.png?auto=format&') no-repeat no-repeat center
      center;
  background-position-x: right;
`;

const Container = styled.div`
  ${DefaultHeroStyles};
  background-size: cover;
  display: flex;
  padding: 23px 0px 0px 0px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    ${MobileHeroStyles};
    background-size: cover;
  }
`;

const Hero = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  max-width: 1234px;
  margin: 0 auto;
  padding: 0 25px 103px;
  z-index: 1;
`;

const NavWrapper = styled.div`
  color: white;
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
`;

const StyledPinIcon = styled(PinIcon)`
  margin-left: 18px;
  margin-right: 8px;
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const TextWrapper = styled.div`
  margin-top: 168px;
  max-width: 585px;
  h1 {
    font-size: 40px;
    font-weight: bold;
    line-height: 49px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    color: white;
  }
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    max-width: 552px;
  }
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    margin: 95px auto 0;

    h1 {
      font-size: 31px;
      line-height: 39px;
    }
  }
`;

const MobileAddressButton = styled(Button)`
  border-radius: 11px;
  margin-top: 53px;
  height: 60px;
  width: 100%;
  text-transform: none;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey[45]};
  background-color: ${({ theme }) => theme.colors.white};

  svg {
    margin-right: 6px;
  }
`;
