import React from 'react';
import styled from 'styled-components';

import { Footer, Grid } from 'components/core';
import GlobalFooterContent from 'components/global-footer-rebrand/content';

export default function HomePageFooter() {
  return (
    <Footer>
      <Container data-ga-label='footer'>
        <GlobalFooterContent />
      </Container>
    </Footer>
  );
}

const Container = styled(Grid)`
  max-width: 1534px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: -1px;
`;
