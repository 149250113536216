import React from 'react';

function SvgExitIcon2(props) {
  return (
    <svg viewBox='0 0 20 20' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.37643 0.450186C2.78701 -0.143226 1.82713 -0.143226 1.23772 0.450187C0.653902 1.03796 0.653902 1.98673 1.23772 2.5745L7.55743 8.93705L1.11266 15.4255C0.528851 16.0133 0.528851 16.962 1.11267 17.5498C1.70208 18.1432 2.66196 18.1432 3.25138 17.5498L9.68179 11.0758L16.1083 17.5459C16.6977 18.1393 17.6576 18.1393 18.247 17.5459C18.8308 16.9581 18.8308 16.0093 18.247 15.4215L11.8062 8.93705L18.1219 2.57846C18.7058 1.99069 18.7058 1.04191 18.1219 0.454144C17.5325 -0.139269 16.5726 -0.139269 15.9832 0.454144L9.68179 6.79829L3.37643 0.450186Z'
        fill='#B2B8C3'
      />
    </svg>
  );
}

export default SvgExitIcon2;
