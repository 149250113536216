import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import useTranslation from 'hooks/use-translation';

import IncentiveCard from 'components/incentive-card';
import { StoreIcon, EnjoyIcon, CarIcon, OrderBagIcon } from 'assets/marketing/rebrand';

export default function IncentiveCards() {
  const { t } = useTranslation();
  return (
    <Container container>
      <CenteredGrid item xs={12} sm={6} md={3}>
        <IncentiveCard
          icon={<StoreIcon />}
          title={t('home.shopLocal', 'Shop local')}
          description={t('home.browseMenus', 'Browse menus from recreational and medicinal dispensaries in your area.')}
        />
      </CenteredGrid>
      <CenteredGrid item xs={12} sm={6} md={3}>
        <IncentiveCard
          icon={<OrderBagIcon />}
          title={t('home.allYourFavorites', 'All your favorites')}
          description={t('home.browseProducts', 'Shop your favorite flower, edibles, vaporizers and more!')}
        />
      </CenteredGrid>
      <CenteredGrid item xs={12} sm={6} md={3}>
        <IncentiveCard
          icon={<EnjoyIcon />}
          title={t('home.orderOnline', 'Order online')}
          description={t('home.orderFromHome', 'Order from the comfort of your own home. Kick back, relax, and enjoy.')}
        />
      </CenteredGrid>
      <CenteredGrid item xs={12} sm={6} md={3}>
        <IncentiveCard
          icon={<CarIcon />}
          title={t('home.pickupAndDelivery', 'Pickup & delivery')}
          description={t(
            'home.skipTheLine',
            'Skip the line with in-store or curbside pickup, or delivery to your door.'
          )}
        />
      </CenteredGrid>
    </Container>
  );
}

const Container = styled(Grid)`
  max-width: 1204px;
  margin: 80px auto 0;
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    margin: 0 auto;
  }
`;

const CenteredGrid = styled(Grid)`
  justify-content: center;
  display: flex;

  > * {
    text-align: center;
    ${({ theme }) => theme.breakpoints.down(`sm`)} {
      text-align: left;
    }
  }
  h3 {
    ${({ theme }) => theme.breakpoints.down(`sm`)} {
      margin-top: 0px;
    }
  }
  p {
    max-width: 226px;
    font-size: 13px;
    line-height: 21px;
    ${({ theme }) => theme.breakpoints.down(`sm`)} {
      line-height: 20px;
      margin-top: 8px;
      max-width: 192px;
    }
  }
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    margin-top: 75px !important;
  }
`;
