/* eslint-disable max-len */
import React from 'react';

const AccountIcon = (props) => (
  <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M26 13C26 5.81505 20.1857 0 13 0C5.81505 0 0 5.81425 0 13C0 20.1213 5.76566 26 13 26C20.2046 26 26 20.154 26 13ZM13 1.92593C19.3282 1.92593 24.0741 6.67178 24.0741 13C24.0741 15.3149 23.9065 17.3746 22.6296 19.2593C17.5023 13.7439 8.50701 13.734 3.37037 19.2593C2.0935 17.3746 1.92593 15.3149 1.92593 13C1.92593 6.67178 6.67178 1.92593 13 1.92593ZM4.81482 20.7037C9.37362 15.5903 16.6278 15.5915 21.1852 20.7037C16.3704 25.037 9.62963 25.5185 4.81482 20.7037Z'
      fill='#C7EFE0'
    />
    <path
      d='M13 13.7617C15.52 13.7617 17.5703 11.7114 17.5703 9.19141V7.66797C17.5703 5.14795 15.52 3.09766 13 3.09766C10.48 3.09766 8.42969 5.14795 8.42969 7.66797V9.19141C8.42969 11.7114 10.48 13.7617 13 13.7617ZM10.1111 7.66797C10.1111 5.98782 11.3199 4.81481 13 4.81481C14.6801 4.81481 15.8889 5.98782 15.8889 7.66797V9.19141C15.8889 10.8716 14.6801 12.037 13 12.037C11.3199 12.037 10.1111 10.8716 10.1111 9.19141V7.66797Z'
      fill='#C7EFE0'
    />
  </svg>
);

export default AccountIcon;
