import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { Button } from 'components/core';

import ROUTES from 'src/routes';

export default function TechnologyForRetailers(): JSX.Element {
  const { t, Trans } = useTranslation();

  return (
    <Section container data-ga-label='for-retailers'>
      <ImageContainer>
        <DispensaryImage />
      </ImageContainer>
      <RightColumn>
        <Content>
          <Title>
            {/* this hypen is a special 'em dash', copied off of google */}
            <Trans i18nKey='home.psst'>Grow your dispensary with Dutchie.</Trans>
          </Title>
          <P>
            <Trans i18nKey='home.dutchieHasEverything'>
              Dutchie has everything you need to easily run your cannabis business, all in one place. From point of sale
              to ecommerce to payments, we offer user-friendly, integrated solutions to help you start—or scale—selling
              cannabis quickly in-store and online.
            </Trans>
          </P>
          <ButtonContainer href={ROUTES.DISPENSARY_B2B}>
            <StyledButton size='medium'>{t('home.learnMore', 'Learn more')}</StyledButton>
          </ButtonContainer>
        </Content>
      </RightColumn>
    </Section>
  );
}

const ButtonContainer = styled.a``;

const StyledButton = styled(Button)`
  background: transparent;
  border: 1px solid #171a1c;
  color: #171a1c;
  text-transform: none;
`;

const DispensaryImage = styled.div`
  background: url('https://images.dutchie.com/marketing-v3/planet13.png') no-repeat center;
  background-size: cover;
  position: absolute;
  left: 0;
  height: 100%;
  width: 50%;
  min-height: 269px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    min-height: 350px;
    background: url('https://images.dutchie.com/marketing-v3/planet13-mobile.png') no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
  }
`;

const Content = styled.div`
  max-width: 667px;
  margin: 0 auto;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    font-size: 28px;
    line-height: 28px;
    max-width: 291px;
    margin: 0 auto 19px;
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  min-height: 269px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    position: relative;
  }
`;

const RightColumn = styled.div`
  background: #fff;
  color: #171a1c;
  height: auto;
  right: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55%;
  padding: 100px 0;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    width: 100%;
    min-height: 361px;
    margin-top: 50px;
  }
`;

const P = styled.p`
  font-size: 18px;
  line-height: 32px;
  margin: 18px 0 34px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    font-size: 13px;
    line-height: 23px;
    max-width: 273px;
  }
`;

const Section = styled.section`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    flex-direction: column;
    height: 100%;
  }
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    display: inherit;
  }
`;
