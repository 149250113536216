import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import useUser from 'hooks/use-user';

import { MobileOnly, DesktopOnly } from 'shared/components';
import { useModals, ModalNames } from 'components/modals';
import MetaTags from 'components/meta-tags';
// TODO: maybe a good idea to relocate?
import MyAccountDrawer from 'components/global-header/my-account-link/my-account-drawer';

import { AddressDialog } from './components/address-dialog';
import Footer from './components/footer';
import FooterAddition from './components/footer-addition';
import Hero from './components/hero';
import IncentiveCards from './components/incentive-cards';
import BestProductsSection from './sections/best-products';
import ForEveryoneSection from './sections/for-everyone';
import TechnologyForRetailersSection from './sections/technology-for-retailers';
import { TopBanner } from './top-banner';
import MenuBottomDrawer from './components/menu-bottom-drawer';
import { DutchiePaySection } from './sections/dutchie-pay';

function IndexPage() {
  const { openModal } = useModals();
  const { isLoggedIn } = useUser();
  const [showAddressDialog, setShowAddressDialog] = useState(false);
  const [showBottomDrawer, SetShowBottomDrawer] = useState(false);
  const [showAccountDrawer, setShowAccountDrawer] = useState(false);

  const openAddressRequiredModal = () => {
    openModal(ModalNames.addressRequired);
  };

  const toggleFooterModal = () => {
    SetShowBottomDrawer(!showBottomDrawer);
  };

  const toggleAccountDrawer = () => {
    setShowAccountDrawer(!showAccountDrawer);
    SetShowBottomDrawer(false);
  };

  return (
    <Wrapper>
      <MetaTags />
      <TopBanner />
      <Hero openAddressDialog={() => setShowAddressDialog(true)} toggleFooterModal={toggleFooterModal} />
      {/* on our homepage, we want to apply #main-content to the dispensary search input */}
      <main>
        <MobileOnly mediaQuery='xs'>
          <AddressDialog onClose={() => setShowAddressDialog(false)} open={showAddressDialog} />
          <GradientBackground>
            <IncentiveCards />
            <BestProductsSection onCallToActionClick={() => setShowAddressDialog(true)} />
          </GradientBackground>
          <DutchiePaySection />
          <ForEveryoneSection onCallToActionClick={() => setShowAddressDialog(true)} />
          <TechnologyForRetailersSection />
        </MobileOnly>

        <DesktopOnly mediaQuery='sm'>
          <GradientBackground>
            <IncentiveCards />
            <BestProductsSection onCallToActionClick={openAddressRequiredModal} />
          </GradientBackground>
          <DutchiePaySection />
          <ForEveryoneSection onCallToActionClick={openAddressRequiredModal} />
          <TechnologyForRetailersSection />
        </DesktopOnly>

        <MobileOnly>
          <MenuBottomDrawer
            open={showBottomDrawer}
            onClose={toggleFooterModal}
            toggleAccountDrawer={toggleAccountDrawer}
            onCtaClick={() => {
              setShowAddressDialog(true);
              SetShowBottomDrawer(false);
            }}
          />
          <MyAccountDrawer open={showAccountDrawer && isLoggedIn} onClose={toggleAccountDrawer} />
        </MobileOnly>
      </main>
      <footer>
        <FooterAddition />
        <Footer />
      </footer>
    </Wrapper>
  );
}

export default observer(IndexPage);

const Wrapper = styled.div``;

const GradientBackground = styled.div`
  background: none;
  display: inline-block;
  position: relative;

  &:after {
    content: ' ';
    width: 100%;
    height: calc(100% - 18%);
    display: inline-block;
    background: linear-gradient(180deg, rgba(242, 243, 244, 0) 12.37%, #f2f3f4 57.18%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    &:after {
      height: calc(100% - 16%);
    }
  }

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    &:after {
      height: calc(100% - 14%);
    }
  }

  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    &:after {
      height: calc(100% - 9%);
    }
  }
`;
