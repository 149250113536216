import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ARIA_ROLES } from 'shared/constants';
import useTranslation from 'src/hooks/use-translation';
import PinIcon from 'assets/pin-icon';
import { Button } from 'components/core';
import {
  AddressAutocompleteGeolocateButton,
  AddressAutocompleteInput,
  AddressAutocompleteOptionsList,
  AddressAutocompletePopover,
} from 'src/components/address-autocomplete';

import { OrderTypeTabs } from './order-type-tabs';
import { useDesktopAddressCombobox } from './use-desktop-address-combobox';

export const DesktopAddressCombobox = observer(
  (): JSX.Element => {
    const { t } = useTranslation();

    const {
      onChangeOrderType,
      onStartShoppingClick,
      orderType,
      errorMessage,
      onInputChange,
    } = useDesktopAddressCombobox();

    const inputPlaceHolder = t('home.inputPlaceholder', 'Enter your address or city to get started...');
    const ctaText = t('home.ctaText', 'Start shopping');

    return (
      <Wrapper>
        <InputWrapper>
          <StyledPinIcon role={ARIA_ROLES.IMG} title={t('homepage.pin-icon', 'Pin locator icon')} />
          <StyledInput placeholder={inputPlaceHolder} onChange={onInputChange} data-testid='homeAddressInput' />
        </InputWrapper>
        <StyledPopover
          $error={!!errorMessage}
          aria-label={t('homepage.dispensary-combobox', 'Dispensaries near this address')}
        >
          <OrderTypeTabs value={orderType} onChange={onChangeOrderType} />
          <StyledGeolocateButton />
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
          <StyledList wrapText={false} />
        </StyledPopover>
        <StartShoppingButton variant='flora' onClick={onStartShoppingClick}>
          {ctaText}
        </StartShoppingButton>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div`
  max-width: 696px;
  position: relative;
`;

const InputWrapper = styled.div`
  margin-top: 40px;
  height: 74px;
  max-width: 696px;
  position: relative;
`;

const StyledInput = styled(AddressAutocompleteInput)`
  font-size: 17px;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 14px;
  padding: 1px 158px 0 68px;
`;

const StyledPinIcon = styled(PinIcon)`
  height: 27px;
  position: absolute;
  left: 29px;
  top: 50%;
  transform: translateY(-50%);
  fill: ${({ theme }) => theme.colors.grey[45]};
`;

const StyledGeolocateButton = styled(AddressAutocompleteGeolocateButton)`
  padding: 15px 16px;
  svg {
    width: 21px;
    height: 21px;
  }
`;

const StyledList = styled(AddressAutocompleteOptionsList)`
  color: ${({ theme }) => theme.colors.grey[25]};
`;

const ErrorText = styled.div`
  padding: 20px 14px 0;
  color: ${({ theme }) => theme.colors.red[55]};
`;

const StartShoppingButton = styled(Button)`
  position: absolute;
  text-transform: none;
  bottom: 18px; // 74px is the height of the input
  right: 18px;
`;

const StyledPopover = styled(AddressAutocompletePopover)`
  width: 696px;
  margin-top: 2px;
  padding: 21px 22px 22px;
  border-radius: 8px;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px 0px;
  &&& * {
    font-size: 15px;
  }
  ${({ $error, theme }) => $error && `box-shadow: 0 0 0pt 1pt ${theme.colors.red[55] as string}`};
`;
