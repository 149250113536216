import React from 'react';
import { space } from 'styled-system';
import styled from 'styled-components';

import { Link } from 'components/core';

import useTranslation from 'hooks/use-translation';

import ROUTES from 'src/routes';

const HeroLinks = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <HeroLinksContainer>
      <Link href={ROUTES.ROOT}>
        {/* eslint-disable-next-line styled-components-a11y/anchor-is-valid */}
        <HeroLink active ml={56}>
          {t('common.showNow', 'Shop now')}
        </HeroLink>
      </Link>
      <Link href={ROUTES.DISPENSARY_B2B}>
        {/* eslint-disable-next-line styled-components-a11y/anchor-is-valid */}
        <HeroLink ml={40}>{t('common.forBusinesses', 'For businesses')}</HeroLink>
      </Link>
    </HeroLinksContainer>
  );
};

export default HeroLinks;

const HeroLinksContainer = styled.div`
  display: flex;
  padding-bottom: 2px;
`;

const HeroLink = styled.a`
  ${space}
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  white-space: nowrap;
  color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.opal02)};
  font-weight: ${({ active }) => (active ? 600 : `normal`)};
  position: relative;

  ${({ theme, active }) =>
    active &&
    `
      &:after {
        position: absolute;
        content: '';
        border-bottom: 2px solid ${String(theme.colors.white)};
        width: 100%;
        left: 0;
        bottom: -5px;
      }
  `}
`;
